import React from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {ContactUpdateRow} from "./ContactUpdateRow";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import globalTheme from "../../../../../../theme/globalTheme";
import {commonStyles} from "../../../../../../theme/user/styles/common";
import {empty} from "../../../../../../../state/utils/Common";

/**
 * コンポーネントスタイル
 * @returns {{transform: string, top: string, left: string, width: number}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        width: 705,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 *
 *
 * @param {Object} contacts - 住所一覧
 * @param {boolean} otherContactsLoading - 住所更新のロード状態
 * @param t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ContactUpdateList = (
    {
        contacts,
        updateLoading,

        selectedContactUuid,
        setContactUuid,
        t
    }) => {
    const commonTheme = globalTheme._default();
    const common = commonStyles();
    return (
        <>
            <Grid container className={common.tableHeader}>
                <Grid item xs={1}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.contacts.update_modal.list_headers.choice')}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.contacts.update_modal.list_headers.status')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.contacts.update_modal.list_headers.contact_name')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.contacts.update_modal.list_headers.department')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.contacts.update_modal.list_headers.address')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.contacts.update_modal.list_headers.mail_address')}
                    </Typography>
                </Grid>
            </Grid>
            {!empty(contacts) &&
                <Box className={globalTheme.list} data-testid="has-contactss" style={{border: "1px solid #CFCFCF", height: 150}}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={contacts.length}
                                itemSize={50}
                                itemData={{
                                    contacts: contacts,
                                    updateLoading: updateLoading,
                                    selectedContactUuid: selectedContactUuid,
                                    setContactUuid: setContactUuid,
                                    t: t,
                                }}
                            >
                                {ContactUpdateRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            }
        </>
    )
};

export default ContactUpdateList;
